<template>
  <div>
    <br /><br /><br /><br /><br />
    <v-container fluid>
      <h3>Personal info</h3>
      <br /><br />
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="8">
          <div style="padding-left: 20px">
            <v-row v-if="nameeditflag">
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Legal name
                </h3>
                <br />
                <h5>
                  This is the name on your travel document, which could be a
                  license or a passport.
                </h5>
                <br />
                <v-text-field
                  v-model="name"
                  label="Name"
                  name="Name"
                  prepend-icon="person"
                  type="text"
                />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="nameCancel">Cancel</u>
                </h5>
                <br /><br /><br /><br />
                <v-btn color="orange" @click="updateProfile('NAME')" text>
                  Save
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Legal name
                </h3>
                <br />
                <h5>{{ name }}</h5>
                <br />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="nameEdit">Edit</u>
                </h5>
              </v-col>
            </v-row>
          </div>

          <v-divider style="max-width: 50%"></v-divider>
          <br /><br />
          <div style="padding-left: 20px">
            <v-row v-if="gendereditflag">
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Gender
                </h3>
                <br />
                <br />
                <v-select
                  v-model="gender"
                  :items="items"
                  label="Select Gender"
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="genderCancel">Cancel</u>
                </h5>
                <br /><br />
                <v-btn color="orange" @click="updateProfile('GENDER')" text
                  >Save</v-btn
                >
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Gender
                </h3>
                <br />
                <h5>{{ gender }}</h5>
                <br />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="genderEdit">Edit</u>
                </h5>
              </v-col>
            </v-row>
          </div>
          <v-divider style="max-width: 50%"></v-divider>

          <br /><br />
          <div style="padding-left: 20px">
            <v-row v-if="dobeditflag">
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Date of birth
                </h3>
                <br />
                <br />
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Birthday date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="dobCancel">Cancel</u>
                </h5>
                <br /><br />
                <v-btn color="orange" @click="updateProfile('DOB')" text>
                  Save
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Date of birth
                </h3>
                <br />
                <h5>{{ convertDate(this.date) }}</h5>
                <br />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="dobEdit">Edit</u>
                </h5>
              </v-col>
            </v-row>
          </div>
          <v-divider style="max-width: 50%"></v-divider>

          <br /><br />
          <div style="padding-left: 20px">
            <v-row v-if="emaileditflag">
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Email address
                </h3>
                <br />
                <br />
                <v-text-field
                  v-model="username"
                  label="Email"
                  name="Email"
                  prepend-icon="email"
                  type="text"
                />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="emailCancel">Cancel</u>
                </h5>
                <br /><br />
                <v-btn color="orange" @click="updateProfile('EMAIL')" text>
                  Save
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Email address
                </h3>
                <br />
                <h5>{{ username }}</h5>
                <br />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="emailEdit">Edit</u>
                </h5>
              </v-col>
            </v-row>
          </div>
          <v-divider style="max-width: 50%"></v-divider>
          <br /><br />
          <div style="padding-left: 20px">
            <v-row v-if="phoneeditflag">
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Phone number
                </h3>
                <br />
                <br />
                <v-text-field
                  v-model="mobile"
                  type="text"
                  prepend-icon="phone"
                  label="Phone Number"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="phoneCancel">Cancel</u>
                </h5>
                <br /><br />
                <v-btn color="orange" @click="updateProfile('PHONE')" text>
                  Save
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Phone number
                </h3>
                <br />
                <h5>{{ mobile }}</h5>
                <br />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="phoneEdit">Edit</u>
                </h5>
              </v-col>
            </v-row>
          </div>
          <v-divider style="max-width: 50%"></v-divider>

          <br /><br />

          <div style="padding-left: 20px">
            <v-row v-if="countryeditflag">
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Country
                </h3>
                <br />
                <br />
                <v-text-field
                  v-model="country"
                  label="Country"
                  name="Country"
                  type="text"
                />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="countryCancel">Cancel</u>
                </h5>
                <br /><br />
                <v-btn color="orange" @click="updateProfile('COUNTRY')" text
                  >Save</v-btn
                >
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="4">
                <h3
                  style="color: grey darken-1; height: 10px; margin-left: -10px"
                >
                  Country
                </h3>
                <br />
                <h5>{{ country || 'Not Provided' }}</h5>
                <br />
              </v-col>

              <v-col cols="4">
                <h5 style="color: grey darken-1">
                  <u @click="countryEdit">Edit</u>
                </h5>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="6" md="4">
          <v-card class="mx-auto" max-width="400">
            <v-icon size="50px" style="padding-left: 10px" color="red"
              >mdi-lock</v-icon
            >

            <v-card-title class="pb-0">
              Which details can be edited?
            </v-card-title>

            <v-card-text class="text--primary">
              <div>
                Details Turobuddy uses to verify your identity can’t be changed.
                Contact info and some personal details can be edited, but we may
                ask you to verify your identity the next time you book or create
                a listing.
              </div>
              <br />
              <v-divider></v-divider>
              <v-icon size="50px" style="padding-left: 10px" color="red"
                >mdi-eye</v-icon
              >
              <v-card-title class="pb-0">
                What info is shared with others?
              </v-card-title>

              <div>
                Turobuddy only releases contact information for Hosts and guests
                after a reservation is confirmed.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider style="max-width: 50%"></v-divider>

      <br /><br /><br />
    </v-container>
    <tb-footer></tb-footer>
  </div>
</template>
<script>
import Footer from '../components/FooterEl.vue';
import { required, digits, max, regex } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';
import moment from 'moment';
import { extend, setInteractionMode } from 'vee-validate';
import msgHelper from '../../utils/msg-helper';
setInteractionMode('eager');
extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
});

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
});

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
});
export default {
  components: {
    'tb-footer': Footer,
  },

  data: () => {
    return {
      countryeditflag: false,
      phoneeditflag: false,
      emaileditflag: false,
      dobeditflag: false,
      nameeditflag: false,
      gendereditflag: false,
      gender: '',
      country: '',

      activePicker: null,
      date: null,
      menu: false,
      loading: false,
      value: String,

      username: '',
      password: '',
      mobile: '',
      mobileCode: '',
      name: '',
      items: ['Male', 'Female', 'Transgender', 'Others'],
      user_id: '',
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },

  computed: {
    ...mapState({
      packageId: (state) => state.packageId,
    }),
    user: {
      get() {
        return this.$store.state.user;
      },
      set(val) {
        this.$store.commit('setUser', val);
      },
    },
  },

  created() {
    document.title = 'TuroBuddy | Update Profile';
    this.init();
  },
  methods: {
    init() {
      this.name = this.$store.state.user.name;
      this.username = this.$store.state.user.email;
      this.mobile = this.$store.state.user.mobile;
      this.user_id = this.$store.state.user._id;
      this.gender = this.$store.state.user.sex;
      this.date = this.$store.state.user.date_of_birth;
      this.country = this.$store.state.user?.country;
    },
    convertDate(unixDate) {
      return moment.unix(unixDate).format('DD/MM/YYYY');
    },
    nameEdit() {
      this.nameeditflag = true;
    },
    nameCancel() {
      this.nameeditflag = false;
    },
    genderEdit() {
      this.gendereditflag = true;
    },
    genderCancel() {
      this.gendereditflag = false;
    },
    dobEdit() {
      this.dobeditflag = true;
    },
    dobCancel() {
      this.dobeditflag = false;
    },
    emailEdit() {
      this.emaileditflag = true;
    },
    emailCancel() {
      this.emaileditflag = false;
    },
    phoneEdit() {
      this.phoneeditflag = true;
    },
    phoneCancel() {
      this.phoneeditflag = false;
    },
    countryEdit() {
      this.countryeditflag = true;
    },
    countryCancel() {
      this.countryeditflag = false;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    onSelect({ dialCode }) {
      this.mobileCode = dialCode;
    },
    async updateProfile(what) {
      const payload = {
        user_id: this.user_id,
      };
      switch (what) {
        case 'NAME':
          payload.name = this.name;
          break;
        case 'GENDER':
          payload.sex = this.gender;
          break;
        case 'DOB':
          payload.date_of_birth = this.$moment(this.date).unix();
          break;
        case 'EMAIL':
          payload.email = this.username;
          break;
        case 'PHONE':
          payload.mobile = '+' + this.mobile;
          break;
        case 'COUNTRY':
          payload.country = this.country;
          break;
      }
      try {
        const response = await this.$http.post(
          'updateUser',
          JSON.parse(JSON.stringify(payload))
        );

        await this.$store.commit('setUser', response.data.data);
        this.init();
        msgHelper.showToast(
          'success',
          'Your account has been successfully updated'
        );
      } catch (err) {
        console.log(err);
        if (err.response) {
          msgHelper.showToast('error', err.response.data.message);
        } else {
          msgHelper.showToast(
            'error',
            'Failed to update the account, please try again later'
          );
        }
      }
    },
  },
};
</script>
